<template>
  <q-btn>
    <img src="../../assets/icons/context-menu.svg" alt="" />
    <q-menu class="context-menu">
      <q-list>
        <q-item clickable v-close-popup @click="showEditTagDialog(tag)">
          Изменить
        </q-item>
        <q-item v-if="tag.termsCount === 0" clickable v-close-popup @click="showDeleteTagDialog(tag)">
          Удалить
        </q-item>
      </q-list>
    </q-menu>
  </q-btn>
</template>

<script>
import EditTagDialog from '../../dialogs/tags/EditTagDialog.vue'
import DeleteTagDialog from '../../dialogs/tags/DeleteTagDialog.vue'

export default {
  name: 'TagContextMenu',
  props: {
    tag: {
      type: Object,
      required: true,
    },
    hook: {
      type: Function,
      required: true,
    },
    view: {
      type: String,
      default: 'menu',
    }
  },
  methods: {
    showEditTagDialog(tag) {
      this.$root
        .createDialog({
          component: EditTagDialog,
          componentProps: {
            tag: tag,
            hook: this.hook,
          },
        })
        .onOk(tag => {
          this.$root.showSuccessMessage(`Тег «${tag.name}» обновлён.`)
        })
    },
    showDeleteTagDialog(tag) {
      this.$root
        .createDialog({
          component: DeleteTagDialog,
          componentProps: {
            tag: tag,
            hook: this.hook,
          },
        })
        .onOk(tag => {
          this.$root.showSuccessMessage(`Тег «${tag.name}» удалён.`)
        })
    },
  },
}
</script>
