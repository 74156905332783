<template>
  <q-dialog ref="dialog" persistent @hide="hide">
    <q-card class="dialog-content">
      <q-btn class="dialog-close" flat round dense v-close-popup>
        <img src="../../assets/icons/close.svg" alt="Закрыть окно" title="Закрыть окно">
      </q-btn>
      <div class="dialog-header">
        <img src="../../assets/icons/dialog/delete.svg" class="dialog-header-icon" alt="">
        {{ dialogTitle }}
      </div>

      <q-banner v-if="error" inline-actions class="text-white bg-red">{{ error }}</q-banner>

      <q-form @submit.prevent="onSubmit">
        <q-input
          v-model="form.name.value"
          label="Название тега"
          outlined
          required
          :error="form.name.error != null"
          :error-message="form.name.error"
          maxlength="200"
        />

        <q-input
          v-model="form.description.value"
          label="Описание тега"
          outlined
          type="textarea"
        />

        <div class="dialog-actions">
          <q-btn label="Отменить" flat class="cancel-button" @click.prevent="onCancelClick" />
          <q-btn label="Сохранить" type="submit" color="primary" :loading="processing" />
        </div>
      </q-form>
    </q-card>
  </q-dialog>
</template>

<script>
import { api as tagsApi } from '../../api/tags.js'

export default {
  name: 'EditTagDialog',
  props: {
    tag: {
      type: Object,
      required: true,
    },
    hook: {
      type: Function,
      required: true,
    },
  },
  emits: ['ok'],
  data() {
    return {
      processing: false,
      error: null,

      form: {
        name: {
          value: this.tag.name,
          error: null,
        },
        description: {
          value: this.tag.description,
          error: null,
        },
      }
    }
  },
  methods: {
    show () {
      this.$refs.dialog.show()
    },
    hide () {
      this.$refs.dialog.hide()
    },
    onCancelClick () {
      this.hide()
    },
    onSubmit() {
      const tag = {}
      tag.id = this.tag.id
      tag.name = this.form.name.value
      tag.description = this.form.description.value

      this.processing = true
      tagsApi
        .save(tag)
        .then(tag => {
          this
            .hook(tag)
            .then(() => {
              this.$emit('ok', tag)
              this.hide()
            })
        })
        .catch(errors => {
          this.processing = false
          if (Array.isArray(errors)) {
            errors.forEach(error => {
              if (error.source) {
                switch (error.source) {
                  case 'title':
                    this.form.name.error = error.title
                    break
                  case 'description':
                    this.form.description.error = error.title
                    break
                }
              } else {
                this.error = error.title
              }
            })
          }
        })
    },
  },
  computed: {
    dialogTitle() {
      return !this.tag.id ? 'Новый тег' : 'Редактировать тег'
    },
  }
}
</script>
