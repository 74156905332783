<template>
  <q-dialog ref="dialog" persistent @hide="hide">
    <q-card class="dialog-content">
      <q-btn class="dialog-close" flat round dense v-close-popup>
        <img src="../../assets/icons/close.svg" alt="Закрыть окно" title="Закрыть окно">
      </q-btn>
      <div class="dialog-header">
        <img src="../../assets/icons/dialog/delete.svg" class="dialog-header-icon" alt="">
        Удаление тега
      </div>

      <p>
        Вы действительно хотите удалить тег {{ tag.name }}?
      </p>
      <div class="dialog-actions">
        <q-btn label="Отменить" flat class="cancel-button" @click="onCancelClick" />
        <q-btn label="Удалить" color="primary" @click="onDeleteClick" :loading="processing"/>
      </div>
    </q-card>
  </q-dialog>
</template>

<script>
import { api as tagsApi } from '../../api/tags.js'

export default {
  name: 'DeleteTagDialog',
  props: {
    tag: {
      type: Object,
      required: true,
    },
    hook: {
      type: Function,
      required: false,
    },
  },
  emits: ['ok'],
  data() {
    return {
      processing: false,
      error: null,
    }
  },
  methods: {
    show () {
      this.$refs.dialog.show()
    },
    hide() {
      this.$refs.dialog.hide()
    },
    onCancelClick() {
      this.hide()
    },
    onDeleteClick() {
      this.processing = true
      tagsApi
        .delete(this.tag)
        .then(tag => {
          this
            .hook(tag)
            .then(() => {
              this.$emit('ok', tag)
              this.hide()
            })
        })
        .catch(e => {
          console.log(e)
        })
    },
  }
}
</script>
