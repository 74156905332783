<template>
  <page-header title="Теги">
    <q-btn color="primary" @click="showAddTagDialog">
      <img src="../../assets/icons/button/add.svg" class="xs" title="Добавить тег" alt="">
      <span class="gt-xs">Добавить тег</span>
    </q-btn>
  </page-header>

  <template v-if="tags != null">
    <template v-if="tags.length > 0">
      <q-card class="t-table">
        <div class="t-row t-header">
          <div class="t-field t-field-name">Название тега</div>
          <div class="t-field f-field-count">Количество связанных слов</div>
          <div class="t-field t-field-actions"></div>
        </div>
        <div v-for="tag in tags" :key="tag.id" class="t-row">
          <div class="t-field t-field-name">
            <router-link :to="{ name: 'tags_view', params: { id: tag.id }}">{{ tag.name }}</router-link>
          </div>
          <div class="t-field f-field-count">
            <div class="t-label">Количество связанных слов</div>
            {{ tag.termsCount }}
          </div>
          <div class="t-field t-field-actions">
            <tag-context-menu :tag="tag" :hook="reload" />
          </div>
        </div>
      </q-card>

      <pagination :page="filter.page" :pages="pages" />
    </template>
    <q-card v-else class="q-pa-lg">
      Пока нет тегов.
    </q-card>
  </template>
</template>

<script>
import loading from '../../loading.js'
import { api as tagsApi } from '../../api/tags.js'
import PageHeader from '../../blocks/PageHeader.vue'
import EditTagDialog from '../../dialogs/tags/EditTagDialog.vue'
import TagContextMenu from '../../blocks/tags/TagContextMenu.vue'
import Pagination from '../../blocks/Pagination.vue'

const createFilter = function(query) {
  const filter = {
    page: 1,
  }

  if (query['page']) {
    filter.page = parseInt(query['page'])
  }

  return filter
}

async function preparePage(callback, to) {
  loading.start()
  const filter = createFilter(to.query)
  const tags = await tagsApi.findBy(filter)
  const setInitData = vm => {
    vm.tags = tags
    vm.pages = tags.meta.pages
    vm.setFilter(filter)
  }
  callback(setInitData)
  loading.stop()
  loading.updateTitle(to.meta.title || '')
}

export default {
  components: {
    PageHeader,
    TagContextMenu,
    Pagination,
  },
  async beforeRouteEnter(to, from, next) {
    await preparePage(setInitData => {
      next(setInitData)
    }, to)
  },
  async beforeRouteUpdate(to, from, next) {
    await preparePage(setInitData => {
      setInitData(this)
      next()
    }, to)
  },
  data() {
    return {
      tags: null,
      filter: {
        page: 1,
      },
      pages: 0,
    }
  },
  methods: {
    async reload() {
      this.tags = await tagsApi.findBy(this.filter)
    },
    showAddTagDialog() {
      const tag = tagsApi.create()

      this.$root
        .createDialog({
          component: EditTagDialog,
          componentProps: {
            tag: tag,
            hook: this.reload,
          },
        })
        .onOk(tag => {
          this.$root.showSuccessMessage(`Тег «${tag.name}» добавлен.`)
        })
    },

    setFilter(filter) {
      this.filter = filter
    },
  }
}
</script>

<style lang="scss">
@import "~@/spa/styles/quasar.variables.scss";

.f-field-count {
  text-align: center;

  @media (max-width: $breakpoint-xs-max) {
    text-align: left;
  }
}
</style>
